.header {
    background-color: #333;
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
  }
  
  .nav-menu {
    display: flex;
    justify-content: center;
  }
  
  .menu-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .menu-item {
    margin-right: 20px;
  }
  
  .menu-link {
    color: white;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .menu-link:hover {
    background-color: #555;
  }