.home {
  text-align: center;
}

.hero {
  background-color: #000000;
  padding: 100px 20px;
}

.hero-content {
  max-width: 600px;
  margin: 0 auto;
}

.hero h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.hero p {
  font-size: 18px;
  margin-bottom: 30px;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}


.app-store-badge {
  width: 150px;
  height: auto;
  border: 2px solid white;
  border-radius: 8px;
  padding: 8px;
}

.features {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding: 60px 20px;
}

.feature {
  flex-basis: 300px;
}

.feature i {
  font-size: 48px;
  margin-bottom: 20px;
}

.feature h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.feature p {
  font-size: 16px;
}