.terms-and-conditions {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
  }
  
  .terms-and-conditions h1 {
    font-size: 32px;
    margin-bottom: 30px;
  }
  
  .terms-and-conditions section {
    margin-bottom: 40px;
  }
  
  .terms-and-conditions h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .terms-and-conditions p {
    font-size: 16px;
    line-height: 1.6;
  }