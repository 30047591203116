.contact {
    max-width: 600px;
    margin: 0 auto;
    padding: 40px;
  }
  
  .contact h1 {
    font-size: 32px;
    margin-bottom: 30px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }
  
  .form-group textarea {
    height: 150px;
  }
  
  button[type='submit'] {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }