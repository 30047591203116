.privacy-policy {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
  }
  
  .privacy-policy h1 {
    font-size: 32px;
    margin-bottom: 30px;
  }
  
  .privacy-policy section {
    margin-bottom: 40px;
  }
  
  .privacy-policy h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .privacy-policy p {
    font-size: 16px;
    line-height: 1.6;
  }